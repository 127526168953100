<template>
  <div class="container">
    {{bank}}
    <b-button @click="$refs.pdf.to_pdf()">Abrir</b-button>
    <b-button @click="bank = 'Inter'">Inter</b-button>
    <b-button @click="bank = 'Bradesco'">Bradesco</b-button>
    <BankTicketPdf
      ref="pdf"
      :unityData="unityData"
      :data="clientData"
      :barcode="barcode"
      :digitableLine="digitableLine"
      :bank="bank"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      bank: "Inter",
      barcode: "23793391266000506205801000685709488690000011990",
      digitableLine: "23793.39126 60005.062058 01000.685709 4 88690000011990",
      clientData: {
        pageContent: [
          {
            id: "560f8708c-f2c8-4255-b446-1af4e268677",
            unity_id: "56ab3078-17ab-4489-96a8-79ee36783afa",
            content_type_id: "374662fe-a910-4232-a7bc-92b5a804a879",
            menu_id: null,
            title: "Pagamento Matrícula / reserva de vaga 2023",
            subtitle:
              "Para garantia da vaga é necessário o preenchimento do requerimento e pagamento da antecipação 1ª parcela da anuidade.",
            description: null,
            warning:
              "Avisos importantes:   nonoo ono no n no no no on on on on no no ",
            content_footer:
              "Declaro ciência das seguintes condições em que esta reserva é feita, e com elas manifesto minha concordância:<br><br>\n\n- O valor da reserva de vaga equivale à primeira parcela do valor da anuidade, de acordo com o período definido, e deverá ser confirmada mediante efetivação de pagamento.\n<br>- Para garantia da vaga é necessário o preenchimento do requerimento e pagamento da antecipação 1ª parcela da anuidade.\n<br>- A matrícula somente será formalizada com minha assinatura e da instituição de ensino no Contrato de Prestação de Serviços Educacionais para o ano letivo requerido, além do pagamento relativo a reserva de vaga.\n<br>- As vagas serão preenchidas conforme ordem de entrega deste requerimento. As fichas subsequentes, serão direcionadas para a lista de espera.",
            active: "0",
            created_at: "2021-11-19T10:39:25.000Z",
          },
        ],
        unityBankAccount: [
          {
            id: "a7bdbf8e-a219-4575-9f0b-9457e073906b",
            company_id: null,
            unity_id: "56ab3078-17ab-4489-96a8-79ee36783afa",
            beneficiary_name: "Empresa 2",
            account_type: "C/C",
            agency: "247",
            account: "1111111",
            beneficiary_document: "06719958000113",
            pix_key: "",
            compe: "237",
            bank_name: "Bradesco",
            ispb: "60746948",
          },
        ],
        invoice: [
          {
            id: "8024d140-ac35-4096-b617-5e275222a574",
            unity_id: "56ab3078-17ab-4489-96a8-79ee36783afa",
            application_id: "f504361b-f730-4748-ba63-c6dc4a2b94ee",
            client_id: "7f8807d-f4a7-448f-a23a-664f68ee06d5",
            financial_client_id: "05dfd7a3-f685-49ce-ab5e-fab8c8c1f6f4",
            enrollment_id: "cb8d265a-fa4b-4185-9032-b7258c66fab1",
            description: null,
            venciment_day: "2023-01-06T00:00:00.000Z",
            validity: "2024-01-06T00:00:00.000Z",
            total_document: null,
            total: "1570.000",
            tax_value: null,
            discount_value: null,
            payment_type_id: "",
            payment_date: null,
            bank_number: "00000004379",
            barcode: "23791914200000004203390090000000437900017510",
            line: "23793.39001 90000.000431 79000.175103 1 91420000000420",
            number_document: "780",
            flow_id: "5",
            status_id: "4",
            created_at: "2022-10-07T09:34:08.000Z",
          },
        ],
        items: [
          {
            id: "039a6f11-a6ef-11ed-9621-0242c0a81002",
            application_id: "f504361b-f730-4748-ba63-c6dc4a2b94ee",
            unity_id: "56ab3078-17ab-4489-96a8-79ee36783afa",
            client_id: "c70450eb-0ff8-473f-b3ea-782724c2a00f",
            enrollment_id: "5c827300-0480-456a-9728-35d2e0c37c32",
            order_id: "fe9f455d-ea60-4123-abc6-af8359e2d76a",
            order_item_id: null,
            plan_id: "",
            enrollment_value_id: "",
            product_id: "2c9cd917-8a5e-4a97-aa24-e426d640d361",
            product_type_id: "",
            description: "Pagamento  a vista",
            number: 1,
            installment_number: null,
            sequence: "1/1",
            type: "percentage",
            amount: "180.000",
            value: "180.000",
            total: "200.000",
            initial_value: "200.000",
            venciment_day: "2023-05-11T00:00:00.000Z",
            invoice_id: "8024d140-ac35-4096-b617-5e275222a574",
            status_id: null,
            status: "open",
            created_at: "2023-02-07T10:55:00.000Z",
            financial_client_id: "c5ff9e50-eda3-4f8b-ad14-cbf63e520c17",
          },
        ],
        paymentType: [
          {
            id: "3",
            description: "Deposito bancário",
          },
          {
            id: "1",
            description: "Boleto",
          },
          {
            id: "4",
            description: "PIX",
          },
        ],
        financialClient: {
          financial_cpf: "485.758.273",
          financial_name: "rubens",
          address: {
            zip_code: "04.285-000",
            address: "Rua Elba",
            city: "São Paulo",
            number: "123",
            complement: "",
            district: "Vila Moinho Velho",
            state: "SP",
          },
        },
        unity: [
          {
            commercial_name: "Empresa 2",
            contact_name: "Empresa 2",
            address: "rua barriga grande 1323 - São paulo - Sao caetano",
            code: "55",
            state: "Sao paulo",
            complement: "apto 80",
            email: "empresa2@gmail.com.br",
            logo: "https://apiserver.com.br/image/uploads/159946fd-5693-436e-9491-0195006bf708/logo.svg",
          },
        ],
      },
    };
  },
  computed: {
    unityData() {
      return this.clientData.unityBankAccount[0];
    },

    invoices() {
      return this.clientData.invoice;
    },
    bankData() {
      return this.clientData.unityBankAccount[0];
    },
    financialClientData() {
      return this.clientData.financialClient;
    },
    address() {
      const address = this.clientData.financialClient.address;

      return address;
    },
  },
  components: {
    BankTicketPdf: () => import("./components/BankTicketPdf.vue"),
  },
};
</script>

<style>
</style>